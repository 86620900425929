import axios from 'axios';
import * as React from 'react';

import {LetterList, NavMenu} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import {slugify} from 'utility';
import _ from 'lodash';

import 'less/models.less';
import {BrComponent} from '@bloomreach/react-sdk';

export default class Models extends React.Component {
    constructor(props) {
        super(props);

        this.findMatches = this.findMatches.bind(this);
        this.state = {
            allModels: [],
            oems: [],
        };
    }

    componentDidMount() {
        axios.get('/CatalogService/api/v1/models').then(response => {
            const {data} = response;
            this.setState({
                oems: Object.keys(data).map(k => {
                    return {name: k, slugName: slugify(k)}
                }),
                allModels: _.flatMapDeep(Object.keys(data).map(oemName => data[oemName].map(mc => {
                    return {
                        name: mc.modelCode,
                        slugName: slugify(mc.modelCode),
                        detailUrl: mc.detailUrl,
                        oem: oemName,
                        oemSlugName: slugify(oemName)}
                }))),
            });
        });
    }

    findMatches(search) {
        const {allModels} = this.state;
        const matches = allModels.filter(mc => mc.slugName.indexOf(search) !== -1);

        this.setState({
            search: search,
            matches: matches,
        });

        let groups = _.groupBy(matches, this.groupCharacters);

        Object.keys(groups).forEach(x => {
            let oemsPerLetter = _.clone(groups[x]);
            groups[x] = [];

            let oemsGroup = _.groupBy(oemsPerLetter, 'oem');

            _.map(oemsGroup, function (g) {
                const oem = {
                    name: g[0].oem,
                    slugName: slugify(g[0].oem),
                }
                groups[x].push(oem);
            });
        });

        return {
            search: search,
            matches: groups,
        };
    }

    groupCharacters(item) {
        const ch = item.oem[0];
        if (ch >= '0' && ch <= '9')
            return '#';

        return ch.toUpperCase();
    }

    render() {
        const {oems, allModels} = this.state;
        return (<div className="models-page">
            <PageMetaData
                title="Shop"
                pageType="other"
                trackAnalytics={false} />
            <NavMenu
                isPDP={true}
                className="header-nav-menu"
                options={[{label: 'Home', url: ''},
                    {label: 'All Models', url: ''}]} />
            <div className="models-page_header">
                <h1>Models</h1>
            </div>
            <div className="top-cms-container ps-hidden-sm ps-hidden-xs">
                <BrComponent path={'main/container'} />
            </div>
            <LetterList renderWithHeaders={true} allModels={allModels} items={oems} urlPath={'models'} title={'Models'} letterTitle={`OEM`}
                findMatchingItems={this.findMatches} />
        </div>);
    }
}
